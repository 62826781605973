import { omit } from "lodash";

import client from "../client";
import { postQrConfig } from "./postQrConfig";

const postQr = async (data: any) => {
  return await client({
    ...postQrConfig(),
    data: omit(data, "authorization"),
  });
};

export default { postQr };
