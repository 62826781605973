import { AxiosRequestConfig } from "axios";

export const putUserQrDataConfig = (
  authorization: string | undefined,
  qrCodeId: string
): AxiosRequestConfig => ({
  method: "put",
  url: `/me/qrcode/${qrCodeId}/data`,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
    Authorization: authorization && `Bearer ${authorization}`,
  },
});
