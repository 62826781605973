import { AxiosRequestConfig } from "axios";

export const postUserQrConfig = (authorization: string | undefined): AxiosRequestConfig => ({
  method: "post",
  url: `/me/qrcode`,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
    Authorization: authorization && `Bearer ${authorization}`,
  },
});
