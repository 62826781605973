import client from "../client";
import { postTemplateConfig } from "./postTemplateConfig";
import { handleAxiosError } from "@/utils/apiErrorHandler";
import { PostTemplateParams, PostTemplateResponse } from "./types";

/**
 * Creates a new template.
 *
 * @param {PostTemplateParams} params - The parameters for the request.
 * @param {Token} params.token - The authentication token.
 * @param {Template} params.data - The template data to create.
 * @returns {Promise<PostTemplateResponse>} Resolves with the created template data or rejects with an error.
 */
export const postTemplateService = async ({
  token,
  data,
}: PostTemplateParams): Promise<PostTemplateResponse> => {
  if (!token) {
    throw new Error("Authentication token is required for creating a template.");
  }

  try {
    await client(postTemplateConfig({ token, data }));
    return { message: "Template created successfully", name: data?.name };
  } catch (error: any) {
    handleAxiosError(error);
    return { message: "There was an error creating the template.", name: data?.name };
  }
};
