import { UserContextProps } from "@/contexts/UserContext/types";
import {
  getRemainingFreeTrialDays,
  isUserFreeTrial,
  isUserNotAuthorized,
} from "@/services/UserService/UserService";

export const getAlertContent = (content: any, userContext: UserContextProps): string => {
  if (isUserFreeTrial(userContext) || isUserNotAuthorized(userContext)) {
    if (isUserNotAuthorized(userContext)) {
      return content.dashboardAlertTextTrialExpired;
    } else {
      const remainingFreeTrialDays = getRemainingFreeTrialDays(userContext);
      let remainingDaysContent = "";

      switch (true) {
        case remainingFreeTrialDays > 1:
          remainingDaysContent = `${content.dashboardAlertTextTrialActive1_2}${remainingFreeTrialDays} ${content.dashboardAlertTextTrialActive3_1}`; // in 2 days
          break;
        case remainingFreeTrialDays === 1:
          remainingDaysContent = `${content.dashboardAlertTextTrialActive3_3}`; // today
          break;
        default:
      }

      return `${content.dashboardAlertTextTrialActive1}${remainingDaysContent}${content.dashboardAlertTextTrialActive2}`;
    }
  } else {
    return content.dashboardAlertText;
  }
};
