import { omit } from "lodash";

import client from "../client";
import { putUserQrDataConfig } from "./putUserQrDataConfig";

const putUserQrData = async (data: any) =>
  await client({
    ...putUserQrDataConfig(data.authorization, data.qrCodeId),
    data: omit(data, ["authorization", "qrCodeId"]),
  });

export default { putUserQrData };
