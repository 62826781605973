import { AxiosRequestConfig } from "axios";

export const putQrDataConfig = (qrCodeId: string): AxiosRequestConfig => ({
  method: "put",
  url: `/qrcode/${qrCodeId}/data`,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
});
