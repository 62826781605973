import React, { useEffect } from "react";
import { useRouter } from "next/navigation";
import { AxiosResponse } from "axios";
import { split } from "lodash";
import QRCodeStyling from "@npm_leadtech/qr-code-styling";

import putUserQrDataHandler from "@/interface/api/putUserQrData";
import putQrDataHandler from "@/interface/api/putQrData";
import postUserQrHandler from "@/interface/api/postUserQr";
import postQrHandler from "@/interface/api/postQr";

import { setPendingActionCookie } from "@/actions/setPendingActionCookie";

import { QREditorDataToAPIConversor } from "@/services/ApiConversorService/QREditor";
import { getTokenData, removeAuthCookie, setCookie } from "@/services/CookiesService";

import { ActionTypes } from "@/internals/constants/actionTypes";

import { downloadQrAi, downloadQrFramed, REFERENCE_EXITS } from "@/utils/qrEditorHelper";
import { QR_PAGE_INDEX } from "@/utils/qrEditorNavigatorHelper";
import { isClient } from "@/utils/browser";

import { getSiteInfo } from "@/sites";

import { LoadingBarContext } from "@/contexts/LoadingBarContext";
import { QREditorContext } from "@/contexts/QREditorContext";
import { QRProps } from "@/contexts/QREditorContext/types";
import { GlobalContext } from "@/contexts/GlobalContext";
import { UserContext } from "@/contexts/UserContext";

import { isUserLoggedIn } from "../auth/useLogin";
import { useToast } from "../ui/useToast";
import { useApi } from "../api/useApi";

import { paths } from "@/utils/paths";

import { SaveDataOfLoggedUsers } from "@/types/pendingActions";
import { QRAi, QRDownloadFormat, QRStyle } from "@/types/qr";
import { QR_REFERENCE_COOKIE } from "@/containers/AuthContainer/types";
import { postTemplateService } from "@/interface/api/postTemplate";
import { GTM_EVENT_NAME } from "@/internals/constants/events";
import { mapQrTypeToGTM } from "@/utils/mapQrTypeToGTM";

export const useQR = () => {
  const { toast } = useToast();
  const router = useRouter();

  const tokenData = getTokenData();

  const qrEditorContext = React.useContext(QREditorContext);
  const userContext = React.useContext(UserContext);
  const filesUploaderContext = React.useContext(LoadingBarContext);

  const [isLoading, setIsLoading] = React.useState(false);
  const [newData, setNewData] = React.useState(undefined);
  const [isUserLogged, setIsUserLogged] = React.useState(false);

  const { request: requestPutUserQrData, error: errorPutUserQrData } = useApi(
    putUserQrDataHandler.putUserQrData
  );
  const { request: requestPutQrData, error: errorPutQrData } = useApi(putQrDataHandler.putQrData);

  const { request: requestPostUserQrData, error: errorPostUserQrData } = useApi(
    postUserQrHandler.postUserQr
  );
  const { request: requestPostQrData, error: errorPostQrData } = useApi(postQrHandler.postQr);
  const { user } = userContext;

  React.useEffect(() => {
    const isLogged = isUserLoggedIn();
    setIsUserLogged(Boolean(isLogged));
  }, []);

  const saveQrDataOfLoggedInUser = async () => {
    const qrIdExist = !!qrEditorContext?.qrData.qrCodeId;
    const data = await QREditorDataToAPIConversor.getPayload(qrEditorContext, filesUploaderContext);

    const { imageUrl, templateName, ...newStyle } = qrEditorContext?.qrData?.qrStyle ?? {};
    const { createNewTemplate } = qrEditorContext;

    //TODO: review why there is a property "": true in qrStyle
    if (Object.prototype.hasOwnProperty.call(newStyle, "")) {
      delete newStyle[""];
    }

    const formattedStyle = newStyle as QRStyle;
    const templatePayload = {
      style: {
        ...formattedStyle,
      },
      name: templateName,
    };

    let qrData: AxiosResponse<unknown, any>;

    if (qrIdExist) {
      if (tokenData?.isTokenExpired) {
        const pendingAction: SaveDataOfLoggedUsers = {
          type: ActionTypes.SAVE_DATA_OF_LOGGED_USERS,
          payload: {
            qrId: qrEditorContext.qrData.qrCodeId,
            data,
          },
          redirectTo: paths.pricing(),
        };
        setPendingActionCookie(pendingAction);
        router.push(paths.login());
      } else {
        if (createNewTemplate) {
          const { imageUrl: imageCloudinary } =
            await QREditorDataToAPIConversor.getQRStylePayload(formattedStyle);

          const { logoUrl, ...newStyle } = templatePayload.style;

          const saveTemplatePayload = {
            ...templatePayload,
            style: { ...newStyle, imageUrl: imageCloudinary || "" },
          };

          postTemplateService({ token: tokenData?.token, data: saveTemplatePayload });
        }

        qrData = await requestPutUserQrData({
          ...data.payload,
          style: {
            ...data.payload.style,
            imageUrl: data.payload.style.imageUrl === undefined ? "" : data.payload.style.imageUrl,
          },
          qrCodeId: qrEditorContext?.qrData.qrCodeId,
        });
      }
    } else {
      qrData = await requestPostUserQrData({
        ...data.payload,
        qrCodeId: qrEditorContext?.qrData.qrCodeReference,
      });

      if (qrData?.status === 201) {
        const qrCreatedEventData = {
          event: GTM_EVENT_NAME.QR_CREATED,
          "qr-type": mapQrTypeToGTM(qrEditorContext.qrData.type),
          "user-id": user.userId || "",
          email: user.email || "",
        };

        window?.dataLayer?.push(qrCreatedEventData);
      }
    }

    const qrStyle: QRStyle = {
      ...data.newData.qrStyle,
      styleType: qrEditorContext?.qrData?.qrStyle?.styleType,
      controlImageAI: qrEditorContext?.qrData?.qrCodeId
        ? qrEditorContext.qrData.qrStyle.controlImageAI
        : undefined,
    };

    setNewData({
      ...qrEditorContext.qrData,
      data: data.newData.data,
      qrStyle,
    });

    setCookie(QR_REFERENCE_COOKIE, qrEditorContext?.qrData.qrCodeReference);

    return qrData;
  };

  const saveQrDataNotLoggedInUser = async () => {
    const data = await QREditorDataToAPIConversor.getPayload(qrEditorContext, filesUploaderContext);
    const requestQrData = qrEditorContext?.qrData.qrCodeId ? requestPutQrData : requestPostQrData;

    const qrData = await requestQrData({
      ...data.payload,
      qrCodeId: qrEditorContext?.qrData.qrCodeReference,
    });
    const qrStyle: QRStyle = {
      ...data.newData.qrStyle,
      styleType: qrEditorContext?.qrData?.qrStyle?.styleType,
      controlImageAI: qrEditorContext?.qrData?.qrCodeId
        ? qrEditorContext.qrData.qrStyle.controlImageAI
        : undefined,
    };

    setNewData({
      ...qrEditorContext.qrData,
      data: data.newData.data,
      qrStyle,
    });

    setCookie(QR_REFERENCE_COOKIE, qrEditorContext?.qrData.qrCodeReference);

    return qrData;
  };

  const requestQR = async () => {
    let newData = qrEditorContext.qrData;
    const step = qrEditorContext.qrData.currentPageIndex;

    if (step === QR_PAGE_INDEX.ADD_CONTENT || step === QR_PAGE_INDEX.DESIGN_QR) {
      setIsLoading(true);

      if (isUserLoggedIn()) {
        const qrData = await saveQrDataOfLoggedInUser();
        newData = (await qrData?.data) as QRProps<undefined>;
      } else {
        const qrData = await saveQrDataNotLoggedInUser();
        newData = (await qrData?.data) as QRProps<undefined>;
      }
      setIsLoading(false);
      return newData;
    }
  };

  useEffect(() => {
    if (errorPostQrData || errorPostUserQrData || errorPutUserQrData || errorPutQrData) {
      if (
        errorPostQrData?.status === 401 ||
        errorPostUserQrData?.status === 401 ||
        errorPutUserQrData?.status === 401 ||
        errorPutQrData?.status === 401
      ) {
        removeAuthCookie();
        setIsUserLogged(false);
        // TODO: AquÃ­ se llega si el token de un usuario logueado no es vÃ¡lido, por lo que en principio se le redirigirÃ¡ al primer step.
        // Lo ideal para mejorar el flujo serÃ­a que apareciera el modal de login y una vez logueado se le redirigiera al step que convenga (a implementar).
        router.replace(paths.qrEditor());
      } else if (errorPostUserQrData?.status === 402 || errorPutUserQrData?.status === 402) {
        router.replace(paths.myQrCodes());
      } else if (
        errorPostQrData?.data?.code === REFERENCE_EXITS ||
        errorPutQrData?.data?.code === REFERENCE_EXITS ||
        errorPostUserQrData?.data?.code === REFERENCE_EXITS ||
        errorPutUserQrData?.data?.code === REFERENCE_EXITS
      ) {
        toast({
          message: "The value you entered is already taken. Please try a different one",
          type: "error",
          marginBottom: 80,
        });
      } else {
        toast({ message: "genericError", type: "error" });
      }
      setIsLoading(false);
    }
  }, [errorPostQrData, errorPostUserQrData, errorPutUserQrData, errorPutQrData]);

  return {
    requestQR,
    isLoading,
    newData,
  };
};

export const useQRStyle = (
  type?: "svg" | "canvas",
  size?: number,
  errorCorrectionLevel?: "L" | "M" | "Q" | "H"
) => {
  const { context, setContext } = React.useContext(GlobalContext);

  const { domainName } = getSiteInfo(process.env.NEXT_PUBLIC_DOMAIN);
  const ref = React.useRef(null);

  const [qrCode, setQrCode] = React.useState(undefined);
  const [url, setUrl] = React.useState(`https://${domainName}`);
  const download = (
    nameOfFile: string,
    extension: QRDownloadFormat,
    data?: { style: QRStyle; qrUrl?: string; ai?: QRAi }
  ) => {
    const frameStyle = data?.style?.frameStyle;

    if (frameStyle && frameStyle !== "none") {
      downloadQrFramed(qrCode, data.style, extension, nameOfFile);
    } else if (data.ai?.selectedImageUrl) {
      downloadQrAi(data, data.ai.selectedImageUrl, extension, nameOfFile);
    } else {
      // Temporary fix -> QR-4379 to download simple QR with logo in svg
      //TODO: Check if qrCode?.download works ok when qr-code-styling lib gets updated
      downloadQrFramed(qrCode, data.style, extension, nameOfFile);
      //qrCode?.download({ name: nameOfFile, extension });
    }
  };

  const getRawData = async () => {
    const rawData = await qrCode?.getRawData("png");
    return rawData;
  };

  const updateQRCode = (data: { qrStyle: QRStyle; qrUrl?: string; margin?: number }) => {
    const dotsType = data?.qrStyle.dotsStyle;
    const cornerDotType = split(data?.qrStyle.cornerStyle, "-")[1];
    const cornerSquareType = split(data?.qrStyle.cornerStyle, "-")[0];

    qrCode?.update({
      data: data?.qrUrl || url,
      //TODO review this prop, it should be imageUrl or logoUrl (imageUrl its necessary when making a request to the API)
      image: data?.qrStyle.logoUrl || data?.qrStyle.imageUrl,
      imageOptions: {
        margin: 0,
        imageSize: 0.4,
      },
      cornersDotOptions: {
        color: data?.qrStyle?.cornerBackgroundColor,
        type: cornerDotType,
      },
      cornersSquareOptions: {
        color: data?.qrStyle?.cornerBorderColor,
        type:
          cornerSquareType && cornerSquareType === "rounded" ? "extra-rounded" : cornerSquareType,
      },
      dotsOptions: {
        color: data?.qrStyle?.dotsColor,
        type: dotsType,
      },
      backgroundOptions: {
        color: data?.qrStyle?.backgroundColor,
      },
      margin: data?.margin || 0,
    });

    qrCode?.append(ref.current);
  };

  React.useEffect(() => {
    if (!isClient()) return;

    const qr = new QRCodeStyling({
      width: size || 300,
      height: size || 300,
      type: type || "canvas",
      dotsOptions: {
        color: "#000000",
        type: "square",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 20,
      },
      qrOptions: {
        errorCorrectionLevel: errorCorrectionLevel || "Q",
      },
    });
    qr.append(ref.current);

    setQrCode(qr);
  }, []);

  React.useEffect(() => {
    if (qrCode) {
      setContext({ ...context, qrCode });
    }
  }, [qrCode]);

  return {
    updateQRCode,
    download: (nameOfFile: string, extension: QRDownloadFormat, data?) =>
      download(nameOfFile, extension, data),
    setUrl,
    getRawData,
    qrCode,
    url,
    ref,
  };
};
