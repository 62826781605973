import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { maxItemsProps } from "@/components/DoughnutChart/types";

import { QrCodeResponse } from "@/interface/api/getAllQrCodes/types";

import { EditButtonProps } from "./types";

export const onEditButton = ({
  id,
  requestQrCode,
  navigateFromQrToEditor,
  qrCodeEditor,
  router,
  toast,
}: EditButtonProps) => {
  if (id) {
    requestQrCode({ qrId: id })
      .then(({ data }) => {
        const formattedData = data as QrCodeResponse;
        navigateFromQrToEditor(qrCodeEditor, formattedData, router);
      })
      .catch((e) => {
        toast({ message: "genericError", type: "error" });
      });
  }
};

export const getFormtattedDate = (date: string) => {
  dayjs.extend(localizedFormat);
  return dayjs(date).format("ll");
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case "paused":
      return "warning";
    case "draft":
      return "inactive";
    case "active":
      return "success";
    default:
      return "primary";
  }
};

export const isFramedQr = (qrAi, style) => !qrAi && style.frameStyle && style.frameStyle !== "none";

export const SHARED_PROPS: {
  collectionColors: string[];
  maxItemsNumber: maxItemsProps;
} = {
  collectionColors: [
    "#2DCB91",
    "#FF6569",
    "#6AF0FF",
    "#FFCB51",
    "#AC4DCC",
    "#FF70DF",
    "#235BF3",
    "#FF9F0F",
    "#058797",
    "#A2E512",
  ],
  maxItemsNumber: {
    itemsNumber: 10,
    remainingItemsLabel: "Others",
    legendColor: "#A7E4E3",
  },
};
