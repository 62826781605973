import { TemplateData } from "@/data/types/templates";

export const TEMPLATE_VIEW_INDEX = {
  LIST: 0,
  CREATE: 1,
  EDIT: 2,
};

export const sortTemplatesByCreatedAt = (templates: TemplateData[]): TemplateData[] => {
  return [...templates].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
};
