import { omit } from "lodash";

import client from "../client";
import { postUserQrConfig } from "./postUserQrConfig";
import { PostUnsubscribePayload } from "../postUnsubscribe/types";

const postUserQr = async (data: PostUnsubscribePayload) => {
  return await client({
    ...postUserQrConfig(data.authorization),
    data: omit(data, "authorization"),
  });
};

export default { postUserQr };
