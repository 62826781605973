import { omit } from "lodash";

import { putQrDataConfig } from "./putQrDataConfig";
import client from "../client";

const putQrData = async (data: any) =>
  await client({
    ...putQrDataConfig(data.qrCodeId),
    data: omit(data, ["authorization", "qrCodeId"]),
  });

export default { putQrData };
