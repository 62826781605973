import React from "react";
import { TbAlertCircle } from "react-icons/tb";
import { Alert } from "./";
import { Button } from "../Button";
import { AlertContentProps } from "./types";
import { UserContextProps } from "@/contexts/UserContext/types";
import { SubscriptionStatus } from "@/types/subscription";
import { Typography } from "../Typography";
import { getAlertContent } from "./utils";
import { isUserNotAuthorized } from "@/services/UserService/UserService";

const isAccountValidatedAndActived = (userContext: UserContextProps, isUserValidated: boolean) => {
  let isActive = false;

  if (
    !userContext?.user?.email ||
    !isUserValidated ||
    userContext?.user?.subscription?.status === SubscriptionStatus.ACTIVE
  ) {
    isActive = true;
  }

  return isActive;
};
export const DashboardAlert = ({
  isUserValidated,
  userContext,
  className,
  content,
}: AlertContentProps) => {
  if (isAccountValidatedAndActived(userContext, isUserValidated)) return;

  const alertContent = (
    <div className={className}>
      <Typography
        Tag="p"
        classname="Alert__text">
        {getAlertContent(content, userContext)}
      </Typography>
    </div>
  );

  return (
    <div style={{ marginTop: "1.5rem" }}>
      <Alert
        icon={<TbAlertCircle aria-label="alert-icon" />}
        text={alertContent}
        type={isUserNotAuthorized(userContext) ? "error" : "warning"}
        button={
          <Button
            dataQA="upgrade-now-button-link"
            label={content.dashboardAlertBtnLabel}
            buttonColor="white"
            tertiary
            to={content.pricingLink}
            small
            customClass="alert-button"
            type="button"
            rounded={!!content.ctaStyle}
          />
        }
      />
    </div>
  );
};
