type QrTrackingMap = Record<string, string>;

/**
 * Mapping of QR type names used in the frontend to the identifiers
 * that must be sent for tracking in Google Tag Manager (GTM).
 * FIXME: There are also a few more mappings for QR types such as DEFAULT_QR_TYPE_OPTIONS, QRDataTypeMap and QrEditorApiToData.ts => getType method. Unify them all.
 */
const QR_TYPE_TRACKING_MAP: QrTrackingMap = {
  Url: "url",
  URL: "url",
  Webpage: "url",
  Feedback: "feedback",
  SMS: "sms",
  Whatsapp: "whatsapp",
  Email: "email",
  Vcard: "vcard",
  VCard: "vcard",
  Business: "business",
  Social: "social",
  Apps: "apps",
  Video: "video",
  MP3: "mp3",
  Text: "text",
  PDF: "pdf",
  Wifi: "wifi",
  Image: "image",
  YouTube: "youtube",
  Youtube: "youtube",
  Website: "website",
  Event: "event",
  Coupon: "coupon",
  "Url Static": "url-static",
  "List of Links": "listoflinks",
  "List of links": "listoflinks",
  "Image Gallery": "image",
  "Social Media": "social",
};

export const mapQrTypeToGTM = (qrType: string): string => {
  if (!qrType) {
    return "";
  }

  return QR_TYPE_TRACKING_MAP[qrType] || qrType;
};
