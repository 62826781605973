import { AxiosRequestConfig } from "axios";
import { PostTemplateParams } from "./types";

export const postTemplateConfig = ({ token, data }: PostTemplateParams): AxiosRequestConfig => ({
  data,
  method: "POST",
  url: "/me/template",
  headers: {
    Accept: "*/*",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
});
