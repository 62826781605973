import axios from "axios";

interface ApiErrorResponse {
  message?: string;
  [key: string]: any;
}

/**
 * Generic function to handle Axios errors.
 */
export const handleAxiosError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    const { response, config } = error;

    if (response) {
      const { status, data } = response;
      const { message = "An error occurred while processing your request." }: ApiErrorResponse =
        data;

      // TODO: Standardize the error response structure from the backend to ensure consistency across all errors.
      console.error(`[API Error] Status: ${status}, Data:`, data);
      console.error(`Request URL: ${config.url}, Method: ${config.method}`);

      switch (status) {
        case 400:
          throw new Error(message || "Bad request. Please try again.");
        case 401:
          throw new Error("Unauthorized. Please log in again.");
        case 403:
          throw new Error("Forbidden. You don't have permission.");
        case 404:
          throw new Error("Resource not found.");
        case 500:
          throw new Error("Server error. Please try again later.");
        default:
          throw new Error(message || "An unknown API error occurred.");
      }
    } else {
      // Handle network errors, timeout, etc.
      console.error("[Unexpected Error]", error);
      throw new Error("An unknown error occurred.");
    }
  } else {
    // Non-Axios errors (e.g., logic errors)
    console.error("[Non-Axios Error]", error);
    throw new Error("An unknown error occurred.");
  }
};
