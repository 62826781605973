import { AxiosRequestConfig } from "axios";

export const postQrConfig = (): AxiosRequestConfig => ({
  method: "post",
  url: `/qrcode`,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
});
